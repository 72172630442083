import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import actions from "../actions";
import {
  Box,
  Text,
  Stack,
  Image,
  Icon,
  HStack,
  VStack,
  useBreakpointValue,
  useToast,
  Link,
  Heading,
  chakra,
} from "@chakra-ui/react";
import { useCollapse } from "@collapsed/react";
import { KYDEvent as IKYDEvent } from "@common/interfaces/KYDEvent";
import {
  KYDTicketType,
  KYDWaitlistBuyOrder,
  KYDWaitlistOrder,
} from "@common/interfaces/KYDTicket";
import { KYDCart as IKYDCart, KYDCartV2 } from "@common/interfaces/KYDCart";
import { CardHolder, Wallet } from "@common/interfaces/KYDUser";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as AWSAmplify from "aws-amplify";
import Div100vh from "react-div-100vh";

import KYDEventDetail from "./EventDetail";
import KYDEventWallet from "./EventWallet";
import Cart from "./Cart";
import {
  useParams,
  useNavigate,
  Routes,
  Route,
  useSearchParams,
} from "react-router-dom";
import logoFooter from "../lightsmall.png";
import logoFooterDark from "../darksmall.png";

import {
  FaShoppingCart,
  FaCalendar,
  FaMapPin,
  FaDoorOpen,
} from "react-icons/fa";

import {
  analytics,
  AUTH_STATES,
  markdownToLineBreaks,
  ModalType,
  PIXELS,
  showIntercom,
  signinIntercom,
  groupBy,
} from "../utils";
import "react-phone-input-2/lib/bootstrap.css";

import KYDSuccess from "./Success";
import KYDCountdown from "./Countdown";

import { AddToCart, InitiateCheckout, Purchase } from "react-facebook-pixel";
import { useDialog, useDialogWithOptions } from "../Common/Dialog";
import { useErrors } from "../hooks/useErrors";

import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import StripeInterface from "@stripe/stripe-js";
import JoinWaitlistModal, {
  JoinWaitlistModalContext,
  withWaitlistModal,
} from "./JoinWaitlistModal";
import WaitlistCheckout from "./WaitList/WaitlistCheckout";
import WaitlistPaymentMethodConfirmationModal, {
  WaitlistPaymentMethodConfirmationModalContext,
  withWaitlistPaymentMethodConfirmationModal,
} from "./WaitlistPaymentMethodConfirmation";
import { usePolling } from "./WaitList/hooks";
import FullScreenModal, {
  FullScreenModalContext,
  withFullScreenModal,
} from "../Common/FullScreenModal";
import { ticketTypeHasWaitlistBuyOrder } from "./WaitList/utils";
import Markdown from "react-markdown";
import EventFooter from "./EventFooter";

const { Auth } = AWSAmplify;

const InlineText = chakra.span;

export type CheckoutParams = {
  type: "card" | "express" | "free";
  cardHolder?: CardHolder;
  formData?: any;
  payment_token?: any;
};

type AFTER_LOGIN_ACTIONS_KEYS = "remind_me_for_on_sale";

function KYDEvent({
  authState,
  currentUser,
  modalType,
  setModalType,
  setWallet,
  wallet,
}: {
  authState: AUTH_STATES;
  currentUser: CognitoUser | null;
  modalType: ModalType;
  setModalType: (type: ModalType, content?: string) => void;
  setWallet: (wallet: Wallet | null) => void;
  wallet: Wallet | null;
}) {
  const [kydEvent, setKydEvent] = useState<IKYDEvent | undefined>(undefined);
  const [cart, setCart] = useState<KYDCartV2 | null>(null);
  const [draftCart, setDraftCart] = useState<any>({});
  const [waitlistCart, setWaitlistCart] = useState<null | KYDCartV2>(null);
  const [waitlistPaymentSetupUrl, setWaitlistPaymentSetupUrl] = useState("");
  const [waitlistTicketTypes, setWaitlistTicketTypes] = useState<
    KYDTicketType[]
  >([]);
  const [waitlistAction, setWaitlistAction] = useState<"buy" | "sell" | null>(
    null
  );
  const [actionAfterLogin, setActionAfterLogin] = useState<{
    action: AFTER_LOGIN_ACTIONS_KEYS;
    context: any;
  } | null>(null);
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const waitlistPaymentSetupDialog = useDialogWithOptions(
    WaitlistPaymentMethodConfirmationModalContext
  );

  const [postLoginAction, setPostLoginAction] = useState<"waitlist" | null>();

  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [expiresMs, setExpiresMs] = useState(0);
  const dialog = useDialog();
  const waitlistDialog = useDialogWithOptions(JoinWaitlistModalContext);
  const fullScreenModal = useDialogWithOptions(FullScreenModalContext);

  const { event_id } = useParams();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();

  const code = params.get("code");
  const [accessCode, setAccessCode] = useState(code);

  useEffect(() => {
    console.log(params);
  }, [params]);

  const stripeRef = useRef<Stripe>();
  const elementsRef = useRef<StripeElements>();
  const radarRef = useRef<any>();

  const [stripeObject, setStripeObject] = useState<Stripe>(null);
  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no kydEvent, do not run the loadStripe function.
      if (kydEvent && kydEvent.stripe && kydEvent.stripe.publishable_key) {
        const res = await loadStripe(kydEvent.stripe.publishable_key);
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res);
      }
    };
    fetchStripeObject();
  }, [kydEvent]);

  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const linkColor = useBreakpointValue({ base: "#FFF", md: "#4022FC" });
  const collapsedHeight = useBreakpointValue({
    base: kydEvent?.description && kydEvent?.description.length > 50 ? 60 : 30,
    md: 60,
  });

  const linkDecoration = useBreakpointValue({ base: "underline", md: "none" });
  const { getCollapseProps, setExpanded, isExpanded } = useCollapse({
    defaultExpanded: false,
    collapsedHeight: collapsedHeight,
  });

  const [_loading, setLoading] = useState<any>({});
  const toast = useToast();

  const updateLoadingState = useCallback(
    (
      key:
        | "auth"
        | "wallet"
        | "cart"
        | "wallet_silent"
        | "reminder"
        | "code"
        | "waitlist"
        | string,
      value: boolean | string
    ) => {
      setLoading((l: any) => {
        return { ...l, [key]: value };
      });
    },
    []
  );

  const presentAuthModal = () => {
    setModalType(ModalType.SIGN_IN_PHONE_NUMBER);
  };

  const fetchCart = useCallback(
    async (_event_id: string): Promise<KYDCartV2 | null> => {
      try {
        const serverCart = await actions.USER.fetchCart(_event_id);
        return serverCart;
      } catch (err) {}
      return null;
    },
    []
  );

  const fetchWallet = useCallback(
    async (_event_id: string) => {
      try {
        setLoading({ wallet: true });
        const serverWallet = await actions.USER.fetchEventWallet(_event_id);

        if (serverWallet) {
          analytics.t("wallet: fetched wallet");
          signinIntercom({
            user_id: serverWallet.user_id,
            email: serverWallet.email,
            first_name: serverWallet.first_name,
            last_name: serverWallet.last_name,
            hmac: serverWallet.intercom_hmac,
          });
          setWallet({ ...serverWallet });
        }
        setLoading({ wallet: false });
        return serverWallet;
      } catch (err) {}
    },
    [setWallet, code]
  );

  const { start: startWalletPolling } = usePolling<any>({
    poll: async () => {
      const wallet = await fetchWallet(kydEvent.id);
      const hasPendingPayment = wallet.buy_waitlist_orders.find(
        (wo) => wo.status === "PENDING_PAYMENT_METHOD"
      );

      console.log({ hasPendingPayment });

      return !hasPendingPayment;
    },
  });

  const pollWalletForNewTickets = useCallback(
    async (
      checkout_session_id: string
    ): Promise<"success" | "failed" | "notfound" | "duplicate"> => {
      const sleep = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
      };

      if (!checkout_session_id) {
        console.log("No checkout session Id");
        return "failed";
      }
      let attempts = 0;
      let didFind = false;

      let ts = Date.now();
      updateLoadingState("wallet_silent", true);

      if (!_loading[checkout_session_id]) {
        updateLoadingState(checkout_session_id, ts.toString());

        do {
          const serverCart = await fetchCart(event_id!);
          if (
            !serverCart ||
            (serverCart &&
              serverCart.last_checkout_session_id === checkout_session_id)
          ) {
            didFind = true;
            await fetchWallet(event_id!);
            break;
          } else if (
            serverCart.stripe &&
            (serverCart.stripe
              .status as StripeInterface.PaymentIntent["status"]) ===
              "requires_payment_method"
          ) {
            return "failed";
          } else {
            await sleep(2000);
            attempts += 1;
          }
        } while (attempts < 15);
      } else {
        return "duplicate";
      }

      updateLoadingState("wallet_silent", false);
      return didFind ? "success" : "notfound";
    },
    [cart, event_id, fetchCart, fetchWallet, updateLoadingState, _loading]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const checkFor3ds = async () => {
      if (queryParams.get("mode") === "payment") {
        setModalType(ModalType.CHECKING_OUT);
        const checkoutSessionId = queryParams.get("checkout_session_id");
        let purchaseData: Purchase = {
          currency: cart?.currency || "USD",
          value: (cart?.total || 0) / 100,
          num_items: cart.ticket_quantity,
          contents: cart?.display_line_items.map((li) => ({
            id: li.entity_type_id,
            quantity: li.display_quantity,
          })),
        };
        PIXELS.purchase(
          purchaseData,
          checkoutSessionId,
          kydEvent?.pixels && kydEvent.pixels.twitter
            ? kydEvent.pixels.twitter.event_purchase_id
            : null,
          null,
          cart?.id
        );

        const didFind = await pollWalletForNewTickets(checkoutSessionId);
        console.log("Did Find: ", didFind);
        if (didFind === "success") {
          analytics.t("cart: checkout success", {
            value: (cart?.total || 0) / 100,
          });

          setModalType(
            ModalType.CHECK_OUT_SUCCESS,
            `${kydEvent?.display_mode}#${kydEvent?.name}`
          );
        } else if (didFind === "failed") {
          analytics.t("cart: checkout failed", { mode: "3ds" });
          setModalType(ModalType.CHECK_OUT_FAILED);
        } else if (didFind === "duplicate") {
          console.log("Ignore");
        } else {
          analytics.t("cart: checkout timed out", { mode: "3ds" });
          setModalType(ModalType.CHECK_OUT_TIMED_OUT);
        }
      }

      queryParams.delete("checkout_session_id");
      queryParams.delete("mode");

      const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        queryParams.toString();
      window.history.pushState({ path: newUrl }, "", newUrl);
    };

    if (cart) {
      checkFor3ds();
    } else if (queryParams.has("m") && queryParams.get("m") === "1") {
      queryParams.delete("m");
      const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        queryParams.toString();
      window.history.pushState({ path: newUrl }, "", newUrl);
      setModalType(ModalType.SIGN_IN_PHONE_NUMBER, "Register Now");
    }
  }, [cart, pollWalletForNewTickets, kydEvent, setModalType]);

  useEffect(() => {
    const fetchKYDEvent = async (_event_id: string) => {
      updateLoadingState("code", true);
      try {
        const _ = await actions.USER.fetchEvent(_event_id);
        analytics.t("event: fetched event");
        analytics.t("pageview");

        if (_) {
          document.title = _.name;
        }
        setKydEvent(_);
      } catch (err: any) {
        //TODO: show error
        analytics.t("error: failed to load event", {
          message: err && err.message,
        });
      }
      updateLoadingState("code", false);
    };

    if (event_id) {
      fetchKYDEvent(event_id);
      PIXELS.pageview();
    }
  }, [event_id, queryParams, code]);

  useEffect(() => {
    const initCart = async (_event_id: string, _attempts?: number) => {
      const params = {
        id: "draft",
        ticket_types: {},
        total: 0,
      };
      setDraftCart(params);
      if (currentUser) {
        updateLoadingState("cart", true);
        updateLoadingState("wallet", true);
        try {
          const serverCart = await fetchCart(_event_id);
          if (serverCart) {
            analytics.t("cart: fetched cart");
            setCart({ ...serverCart });
            if (
              serverCart.expires_seconds &&
              !queryParams.has("checkout_session_id")
            ) {
              setExpiresMs(Date.now() + serverCart.expires_seconds * 1000);
            } else {
              setExpiresMs(0);
            }
          } else {
            dialog({ text: "No cart found. Please contact support." });
          }
        } catch (err) {
          dialog({ text: err.message });
        }
        updateLoadingState("cart", false);

        try {
          await fetchWallet(_event_id);
        } catch (err) {
          //TODO: show error
          //@ts-ignore
          dialog({ text: err.message });
        }
        updateLoadingState("wallet", false);
      }
    };

    if (event_id) {
      initCart(event_id);
    }
  }, [
    event_id,
    currentUser,
    queryParams,
    fetchCart,
    fetchWallet,
    updateLoadingState,
    dialog,
  ]);

  const isUserLoggedIn = !!(currentUser && currentUser.getSignInUserSession());
  const onJoinWaitlist = useCallback(
    (ticketType: KYDTicketType) => {
      if (ticketTypeHasWaitlistBuyOrder(ticketType, wallet)) {
        setWaitlistAction(null);
        setWaitlistTicketTypes([]);

        return;
      }
      // Open popup to select number of waitlist tickets
      setWaitlistTicketTypes([ticketType]);
      setWaitlistAction("buy");
      waitlistDialog.open({
        title: "Get in line",
        text: "How many tickets do you need? Pro-tip: The fewer tickets you request, the higher your chances we’ll be able find you tickets as they become available! ",
      });
    },
    [setWaitlistTicketTypes, waitlistDialog]
  );

  const onLeaveWaitlist = useCallback(
    async (tt: KYDTicketType, wo: KYDWaitlistBuyOrder) => {
      console.log({ tt, wo });
      setLoading({
        waitlist: true,
      });
      try {
        const response = await actions.USER.cancelWaitlistOrder({
          event_id: kydEvent?.id,
          waitlist_id: wo.id,
        });
        await fetchWallet(kydEvent.id);
        fullScreenModal.open({
          title: "✅ Removed from waitlist",
          text: "You are no longer on the waitlist for the event",
        });
      } catch (e) {
        console.error("Waitlist cancel", e);
        fullScreenModal.open({
          title: "Something is wrong",
          text: e.message,
        });
      } finally {
        setLoading({ waitlist: false });
      }
    },
    [kydEvent]
  );

  const onSignInToJoinWaitlist = useCallback((ticketType: KYDTicketType) => {
    console.log("Sign in to join");
    if (isUserLoggedIn) {
      return;
    }

    setPostLoginAction("waitlist");
    setWaitlistTicketTypes([ticketType]);
    setWaitlistAction("buy");
    setModalType(ModalType.SIGN_IN_PHONE_NUMBER);
  }, []);

  const onAddToCart = useCallback(async () => {
    setModalType(ModalType.SECURING_TICKETS, kydEvent?.display_mode);

    if (currentUser && currentUser.getSignInUserSession()) {
      const tt_ids = Object.keys(draftCart.ticket_types);
      let addToCartPixelData: AddToCart = {
        currency: kydEvent?.currency,
        contents: tt_ids.map((ttid) => ({
          id: ttid,
          quantity: draftCart.ticket_types[ttid],
        })),
        content_type: "product",
      };
      analytics.t("cart: added to cart");

      PIXELS.addtocart(
        addToCartPixelData,
        null,
        kydEvent?.pixels && kydEvent.pixels.twitter
          ? kydEvent.pixels.twitter.event_add_to_cart_id
          : null
      );
      try {
        const cart_result = await actions.USER.updateCart(
          event_id!,
          draftCart,
          accessCode
        );
        if (cart_result.success === true) {
          const newCart = await actions.USER.fetchCart(event_id!);
          setCart({ ...newCart });
          setDraftCart({ total: 0 });

          if (expiresMs === 0 && newCart.expires_seconds) {
            setExpiresMs(Date.now() + newCart.expires_seconds * 1000);
          }
          setModalType(ModalType.INACTIVE);
          const searchParams = new URLSearchParams(window.location.search);
          navigate(`/e/${event_id}/cart?${searchParams.toString()}`);
        } else if (cart_result.success === false && cart_result.message) {
          setModalType(ModalType.INACTIVE);
          //@ts-ignore
          dialog({ text: cart_result.message });
        }
      } catch (err) {
        setModalType(ModalType.INACTIVE);
        //@ts-ignore
        dialog({ text: err.message });
      }
    } else {
      analytics.t("cart: needs auth");
      setDraftCart({ ...draftCart, interupted: true });
      setModalType(ModalType.SIGN_IN_PHONE_NUMBER);
    }
  }, [currentUser, draftCart, event_id, kydEvent, expiresMs]);

  const onRemindMe = useCallback(async () => {
    if (currentUser && currentUser.getSignInUserSession()) {
      setModalType(ModalType.CREATING_REMINDER);
      let remindMePixelData: any = {
        kyd_event_id: kydEvent?.id,
      };
      analytics.t("event: reminder set");

      PIXELS.setReminder(remindMePixelData);

      try {
        await actions.USER.createEventReminder(event_id!);
        setModalType(ModalType.INACTIVE);
        toast({
          title: "✅ Reminder Created",
          description: "We'll send you a text 12 hours before the event",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } catch (err) {
        setModalType(ModalType.INACTIVE);
        dialog({ text: err.message });
      }
    } else {
      setDraftCart({ ...draftCart, reminder: true });
      analytics.t("event: remind me clicked");
      setModalType(ModalType.SIGN_IN_PHONE_NUMBER);
    }
  }, [currentUser, event_id, kydEvent, setModalType, dialog]);

  const onClearCart = useCallback(async () => {
    analytics.t("cart: clear cart");
    setModalType(ModalType.CLEARING_CART);
    if (cart && cart.expiration_token) {
      try {
        setExpiresMs(0);

        await actions.USER.clearCart(event_id!, cart?.expiration_token);
        const newCart = await actions.USER.fetchCart(event_id!);
        setCart({ ...newCart });
        setDraftCart({ total: 0 });

        setModalType(ModalType.INACTIVE);
      } catch (err) {
        setModalType(ModalType.INACTIVE);
        dialog({ text: err.message });
      }
    } else {
      throw Error("No expiration token on cart. Please contact support.");
    }
  }, [event_id, kydEvent, cart]);

  useEffect(() => {
    if (currentUser && currentUser.getSignInUserSession()) {
      if (draftCart && draftCart.total > 0 && draftCart.interupted) {
        onAddToCart();
      } else if (draftCart && draftCart.reminder) {
        onRemindMe();
      } else if (postLoginAction === "waitlist") {
        console.log("Should automatically call join waitlist");
        setPostLoginAction(null);
        onJoinWaitlist(waitlistTicketTypes.at(0));
      }
    }
  }, [currentUser, draftCart, onAddToCart, onRemindMe]);

  const onCheckout = async (params: CheckoutParams) => {
    let { cardHolder, formData, type } = params;
    console.log("Checkout Params: ", params);

    let form_payment_method_id = formData.payment_method_id;
    analytics.t("cart: checked out", { type });

    if (formData) {
      cardHolder = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        billing_address: {
          zip: formData.zip,
        },
        store_for_future_use: formData.store_for_future_use,
      };
    }

    const total = cart?.total || 0;
    const cartId = cart!.id;
    const cartCurrency = cart!.currency;
    const cartSubtotal = total / 100;
    const cartQuantity = cart!.ticket_quantity;
    const cartContents = cart!.display_line_items.map((li) => ({
      id: li.entity_type_id,
      quantity: li.display_quantity,
    }));
    let intiateCheckoutData: InitiateCheckout = {
      currency: cartCurrency,
      value: cartSubtotal,
      num_items: cartQuantity,
      contents: cartContents,
    };
    PIXELS.identify({ email: cardHolder?.email });
    PIXELS.initiatecheckout({
      data: intiateCheckoutData,
      twitterEventId:
        kydEvent?.pixels && kydEvent.pixels.twitter
          ? kydEvent.pixels.twitter.event_initiate_checkout_id
          : null,
      kydEvent,
      eventId: kydEvent?.id,
      cartId,
    });

    setCheckoutLoading(true);
    setModalType(ModalType.CHECKING_OUT, kydEvent?.display_mode);

    try {
      console.log("Payment Method ID: ", form_payment_method_id);
      let payment_method_id = null;
      if (type !== "free") {
        // Trigger form validation and wallet collection
        const { error: submitError } = await elementsRef.current.submit();
        if (submitError) {
          dialog({ text: submitError.message });
        }

        if (!stripeRef || !stripeRef.current) {
          dialog({
            text: "Could not create checkout session. Your card was not charged. Please contact support.",
          });
        }

        if (form_payment_method_id && type === "card") {
          const cvc_element = elementsRef!.current.getElement("cardCvc");
          console.log("CVC Elements: ", cvc_element);

          const { token, error: cvcError } =
            await stripeRef!.current.createToken("cvc_update", cvc_element);

          if (cvcError) {
            dialog({
              text: cvcError.message,
            });
          }
          console.log("CVC Token: ", token);
          cardHolder.cvc_token = token.id;
          payment_method_id = form_payment_method_id;
        } else {
          console.log("Elements: ", elementsRef!.current.getElement("payment"));
          const { paymentMethod, error: pmError } =
            await stripeRef!.current.createPaymentMethod({
              elements: elementsRef!.current,
            });

          if (pmError) {
            dialog({
              text: pmError.message,
            });
          }
          payment_method_id = paymentMethod.id;
        }
      }

      const { checkout_session_id, payment_intent } =
        await actions.USER.createCheckout(
          event_id!,
          total,
          cardHolder,
          type,
          payment_method_id,
          radarRef.current
        );

      setCart(null);
      setExpiresMs(0);

      if (payment_intent && payment_intent.next_action) {
        await stripeRef!.current.handleNextAction({
          clientSecret: payment_intent.client_secret,
        });
      } else {
        console.log("No next action");
        const didFind = await pollWalletForNewTickets(checkout_session_id);

        if (didFind === "success") {
          analytics.t("cart: checkout success", {
            value: (cart?.total || 0) / 100,
          });

          setModalType(
            ModalType.CHECK_OUT_SUCCESS,
            `${kydEvent?.display_mode}#${kydEvent?.name}`
          );
        }
      }
    } catch (err) {
      const serverCart = await fetchCart(event_id!);
      setCart(serverCart);
      if (serverCart?.expires_seconds) {
        setExpiresMs(Date.now() + serverCart.expires_seconds * 1000);
      }
      setModalType(ModalType.INACTIVE);
      console.error(err);
      dialog({
        text:
          err.message ||
          "Could not create checkout session. Please contact support.",
      });
    }

    setCheckoutLoading(false);
  };

  const onCreateWaitlistBuyOrder = async (params: CheckoutParams) => {
    let { cardHolder, formData, type } = params;
    console.log("waitlist checkout Params: ", params);

    let form_payment_method_id = formData.payment_method_id;
    analytics.t("waitlist: checkout", { type });

    if (formData) {
      cardHolder = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        billing_address: {
          zip: formData.zip,
        },
        store_for_future_use: formData.store_for_future_use,
      };
    }

    // const total = cart?.total || 0;
    // const cartCurrency = cart!.currency;
    // const cartSubtotal = total / 100;
    // const cartQuantity = cart!.ticket_quantity;
    // const cartContents = cart!.display_line_items.map((li) => ({
    //   id: li.entity_type_id,
    //   quantity: li.display_quantity,
    // }));
    // let intiateCheckoutData: InitiateCheckout = {
    //   currency: cartCurrency,
    //   value: cartSubtotal,
    //   num_items: cartQuantity,
    //   contents: cartContents,
    // };
    // PIXELS.identify({ email: cardHolder?.email });
    // PIXELS.initiatecheckout(
    //   intiateCheckoutData,
    //   null,
    //   kydEvent?.pixels && kydEvent.pixels.twitter
    //     ? kydEvent.pixels.twitter.event_initiate_checkout_id
    //     : null
    // );

    setCheckoutLoading(true);

    try {
      console.log("Payment Method ID: ", form_payment_method_id);
      let payment_method_id = null;

      // Trigger form validation and wallet collection
      const { error: submitError } = await elementsRef.current.submit();
      if (submitError) {
        dialog({ text: submitError.message });
      }

      if (!stripeRef || !stripeRef.current) {
        dialog({
          text: "Could not create checkout session. Your card was not charged. Please contact support.",
        });
      }

      if (form_payment_method_id && type === "card") {
        const cvc_element = elementsRef!.current.getElement("cardCvc");
        console.log("CVC Elements: ", cvc_element);

        const { token, error: cvcError } = await stripeRef!.current.createToken(
          "cvc_update",
          cvc_element
        );

        if (cvcError) {
          dialog({
            text: cvcError.message,
          });
        }
        console.log("CVC Token: ", token);
        cardHolder.cvc_token = token.id;
        payment_method_id = form_payment_method_id;
      } else {
        console.log("Elements: ", elementsRef!.current.getElement("payment"));
        const { paymentMethod, error: pmError } =
          await stripeRef!.current.createPaymentMethod({
            elements: elementsRef!.current,
          });

        if (pmError) {
          dialog({
            text: pmError.message,
          });
        }
        payment_method_id = paymentMethod.id;
      }

      const waitlistTicketType = waitlistCart.line_items.at(0);

      if (!waitlistTicketType) {
        throw new Error("Ticket type not in waitlist");
      }

      const { id, setup_intent } = await actions.USER.createBuyOrder({
        ticket_type_id: waitlistTicketType.entity_type_id,
        quantity: waitlistCart.ticket_quantity,
        card_holder: cardHolder,
        event_id: kydEvent.id,
        type: "card",
        expected_total: waitlistCart.total,
        payment_method_id: payment_method_id,
        radar_session: radarRef.current,
      });

      if (setup_intent?.next_action?.redirect_to_url?.url) {
        setWaitlistPaymentSetupUrl(
          setup_intent?.next_action?.redirect_to_url?.url
        );

        waitlistPaymentSetupDialog.open({
          title: "Payment method confirmation",
        });
      } else if (id) {
        onPaymentMethodSetupSuccess();
      }
    } catch (err) {
      setModalType(ModalType.INACTIVE);
      console.error(err);
      fullScreenModal.open({
        title: "❌ Something went wrong",
        text:
          err.message ||
          "Could not reserve tickets for waitlist. Please contact support.",
      });
      setCheckoutLoading(false);
    }
  };

  const onPaymentMethodSetupSuccess = useCallback(async () => {
    const wallet = await fetchWallet(kydEvent.id);
    const hasPending = wallet.buy_waitlist_orders.some(
      (wo) => wo.status === "PENDING_PAYMENT_METHOD"
    );

    const onSuccess = () => {
      fullScreenModal.open({
        title: "✅ You've been added to the waitlist!",
        text: "👋 You will be notified if tickets become available and charged on your card.",
      });
      setWaitlistCart(null);
      setWaitlistTicketTypes([]);
      setWaitlistAction(null);
      setCheckoutLoading(false);
      fetchWallet(kydEvent.id);
      navigate(`/e/${kydEvent.id}`);
    };

    if (!hasPending) {
      onSuccess();
      return;
    }

    if (hasPending) {
      console.log("Will start polling");
      const isSuccess = await startWalletPolling();
      if (isSuccess) {
        onSuccess();
      } else {
        fullScreenModal.open({
          title: "❌ Something went wrong",
          text: "We were not able to confirm the payment method",
        });
      }

      setCheckoutLoading(false);
      console.log("Finished polling");
    }
  }, [
    toast,
    setWaitlistCart,
    setWaitlistTicketTypes,
    fetchWallet,
    navigate,
    kydEvent,
  ]);

  useEffect(() => {
    const onMessage = (e) => {
      if (e.data === "3DS-authentication-complete") {
        onPaymentMethodSetupSuccess();
      }
    };

    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [
    kydEvent,
    setWaitlistCart,
    setWaitlistTicketTypes,
    fetchWallet,
    navigate,
    toast,
    onPaymentMethodSetupSuccess,
  ]);

  const [promocodeErrors, updatePromocodeErrors, cleanError] = useErrors();
  const onApplyPromoCode = async (promoCode: string) => {
    analytics.t("cart: apply promo code", { promoCode });

    setCheckoutLoading(true);
    cleanError("promocode");
    return actions.USER.applyPromoCode(event_id!, promoCode)
      .then(async () => {
        const serverCart = await fetchCart(event_id!);
        setCart(serverCart);
      })
      .catch((e) => {
        console.error(e);
        updatePromocodeErrors({
          promocode: {
            message: e.message || "Invalid promo code",
          },
        });

        throw e;
      })
      .finally(() => {
        setCheckoutLoading(false);
      });
  };

  const onRemovePromocode = async (promoCode: string) => {
    console.log("remove");
    analytics.t("cart: remove promo code", { promoCode });

    setCheckoutLoading(true);
    cleanError("promocode");
    return actions.USER.removePromoCode(event_id!, promoCode)
      .then(async () => {
        const serverCart = await fetchCart(event_id!);
        setCart(serverCart);
      })
      .catch((e) => {
        console.error(e);
        updatePromocodeErrors({
          promocode: {
            message: e.message || "Could not remove promocode",
          },
        });

        throw e;
      })
      .finally(() => {
        setCheckoutLoading(false);
      });
  };

  const onUpdateTicketTypeQuantity = useCallback(
    (ticket_type: KYDTicketType, action: string, value = 0) => {
      analytics.t("cart: update quantity", { action, ticket_type });
      if (!draftCart.ticket_types) {
        draftCart.ticket_types = {};
      }

      const current_quantity = draftCart.ticket_types[ticket_type.id] || 0;
      if (
        action === "add" &&
        current_quantity < ticket_type.limit &&
        current_quantity < ticket_type.remaining
      ) {
        draftCart.ticket_types[ticket_type.id] = current_quantity + 1;
      } else if (action === "sub" && current_quantity >= 1) {
        draftCart.ticket_types[ticket_type.id] = current_quantity - 1;
      } else if (action === "set") {
        draftCart.ticket_types[ticket_type.id] = value;
      }

      if (draftCart.ticket_types[ticket_type.id] === 0) {
        delete draftCart.ticket_types[ticket_type.id];
      }

      let total = 0;
      const ticket_types = Object.keys(draftCart.ticket_types);
      ticket_types.forEach((key) => {
        total += draftCart.ticket_types[key];
      });

      draftCart.total = total;

      setDraftCart({ ...draftCart });
    },
    [draftCart, kydEvent]
  );

  const onCartExpire = useCallback(async () => {
    try {
      analytics.t("cart: expired");
      setCart(null);
      setExpiresMs(0);
      const cart = await fetchCart(event_id!);
      if (cart && (cart.status === "EXPIRED" || cart.status === "OPEN")) {
        setModalType(ModalType.EXPIRED_CART);
      }
    } catch (err) {
      setModalType(ModalType.EXPIRED_CART);
    }
  }, [event_id, kydEvent, fetchCart]);

  const onRemindMeWhenOnSale = useCallback(
    async (kydEventId: string) => {
      if (!currentUser || !currentUser.getSignInUserSession()) {
        setActionAfterLogin({
          action: "remind_me_for_on_sale",
          context: kydEventId,
        });

        setModalType(ModalType.SIGN_IN_PHONE_NUMBER);

        return;
      }

      console.log("After login", kydEventId);
      updateLoadingState("reminder", true);
      return actions.USER.createOnSaleReminder(kydEventId)
        .then(async () => {
          await fetchWallet(kydEventId);
          toast({
            title: "Event reminder created",
            description:
              "We'll send you a text as soon as tickets for this event go on sale",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        })
        .catch((e) => {
          console.log(e);
          dialog({
            title: "Get a reminder",
            text: "It wasn't possible to set up a reminder. Please call support or try again later.",
          });
        })
        .finally(() => {
          updateLoadingState("reminder", false);
        });
    },
    [currentUser, dialog, fetchWallet, setModalType, toast]
  );

  const AFTER_LOGIN_ACTIONS: Record<
    AFTER_LOGIN_ACTIONS_KEYS,
    (context?: any) => Promise<void>
  > = useMemo(
    () => ({
      remind_me_for_on_sale: async (kydEventId) => {
        setActionAfterLogin(null);
        return onRemindMeWhenOnSale(kydEventId);
      },
    }),
    [onRemindMeWhenOnSale]
  );

  useEffect(() => {
    if (currentUser && currentUser.getSignInUserSession()) {
      if (actionAfterLogin && actionAfterLogin.action !== null) {
        try {
          AFTER_LOGIN_ACTIONS[actionAfterLogin.action](
            actionAfterLogin.context
          );
        } catch (e) {
          console.log("Error running action");
        }
      }
    }
  }, [currentUser, actionAfterLogin, AFTER_LOGIN_ACTIONS]);

  const goToWaitlistCheckout = async ({
    ticketTypeId,
    quantity,
  }: {
    ticketTypeId: string;
    quantity: number;
  }) => {
    console.log({ ticketTypeId, quantity });
    setLoading({
      waitlist: true,
    });
    const response = await actions.USER.mockWaitlistCart(kydEvent.id, {
      id: ticketTypeId,
      quantity,
    });

    if (!response.statusCode) {
      setWaitlistCart(response);
    }

    setLoading({
      waitlist: false,
    });
    navigate(`/e/${event_id}/waitlist`);
  };

  // A map of ticket types with tickets
  const ticketTypesOfOwnedTickets = useMemo(() => {
    if (!wallet || !kydEvent) return [];

    return groupBy(wallet.tickets, (ticket) => ticket.ticket_type_id);
  }, [wallet, kydEvent]);

  const onCreateWaitlistSellOrder = useCallback(
    async ({
      ticketTypeId,
      ticketIds,
    }: {
      ticketTypeId: string;
      ticketIds: string[];
    }) => {
      // TODO: support multiple ticket types
      setLoading({
        waitlist: true,
      });
      try {
        await actions.USER.createSellOrder({
          event_id: kydEvent?.id,
          ticket_ids: ticketIds,
          ticket_type_id: ticketTypeId,
        });

        fullScreenModal.open({
          title:
            "✅ Confirmed! We'll notify you if we find someone to buy your tickets.",
          actions: [
            {
              text: "My account",
              type: "confirm",
              onClick: () => {
                navigate(`/e/${kydEvent.id}/wallet`);
              },
            },
          ],
        });

        await fetchWallet(kydEvent.id);
      } catch (e) {
        fullScreenModal.open({
          title: "❌ An error occurred",
          text: e.message,
        });
      } finally {
        setLoading({
          waitlist: false,
        });
      }
    },
    [kydEvent, ticketTypesOfOwnedTickets]
  );

  const onListTickets = () => {
    console.log({
      ticketTypesOfOwnedTickets,
      test: Object.keys(ticketTypesOfOwnedTickets).map((ownedTicketTypeId) =>
        kydEvent.ticket_types.find((tt) => tt.id === ownedTicketTypeId)
      ),
    });
    setWaitlistTicketTypes(
      Object.keys(ticketTypesOfOwnedTickets).map((ownedTicketTypeId) =>
        kydEvent.ticket_types.find(
          (tt) => tt.id === ownedTicketTypeId && tt.waitlist_status === "OPEN"
        )
      )
    );
    setWaitlistAction("sell");
    waitlistDialog.open({
      title: "Can't go anymore?",
      text: "You can add your tickets to the waitlist and get a full refund if someone buys it.",
    });
  };
  const descriptionWithLineBreaks = useMemo(() => {
    if (kydEvent?.description) {
      return markdownToLineBreaks(kydEvent.description);
    }
  }, [kydEvent?.description]);

  return (
    <Div100vh>
      <FullScreenModal eventId={kydEvent?.id} />
      <JoinWaitlistModal
        action={waitlistAction}
        ticketTypes={waitlistTicketTypes}
        wallet={wallet}
        onCreateSellOrder={onCreateWaitlistSellOrder}
        onCreateBuyOrder={goToWaitlistCheckout}
        onCancel={() => {
          setWaitlistCart(null);
          setWaitlistTicketTypes([]);
          setWaitlistAction(null);
        }}
        isLoading={_loading.waitlist}
      />
      <WaitlistPaymentMethodConfirmationModal
        onCancel={() => {}}
        onConfirm={() => {}}
        url={waitlistPaymentSetupUrl}
      />

      <VStack
        overflow={"scroll"}
        w="100vw"
        h="100%"
        bgGradient="linear-gradient(322.9deg, #8B8B8B -32.49%, #000000 30.1%, #332E38 97.31%)"
        backgroundSize={"cover"}
        justifyContent={"space-between"}
      >
        <Box zIndex={1000} w="100%" p={0}>
          {kydEvent && kydEvent.status === "PREVIEW" ? (
            <VStack
              justify={"center"}
              zIndex={1001}
              h="75"
              bg="blue.500"
              w="100%"
            >
              <Text fontWeight={"bold"} color="white">
                👀 YOU ARE PREVIEWING THIS EVENT
              </Text>
            </VStack>
          ) : null}
          <VStack zIndex={1001} w="100%">
            <HStack
              px={5}
              justify={"space-between"}
              w="100%"
              h={{ base: "100px", md: "100px" }}
            >
              <Image
                p={{ sm: 2, md: 4 }}
                cursor={"pointer"}
                onClick={() => navigate(`/bio`)}
                src={kydEvent?.organization?.logo_url}
                objectPosition={"left"}
                objectFit={"contain"}
                maxW={{ base: "100px", md: "200px" }}
              />
              <HStack
                justify={"flex-end"}
                spacing={6}
                wrap="wrap"
                color="white"
              >
                {currentUser && (
                  <Text
                    cursor={"pointer"}
                    onClick={() => {
                      analytics.t("nav: log out");
                      setCart(null);
                      setWallet(null);
                      setExpiresMs(0);
                      Auth.signOut();
                      analytics.reset();
                    }}
                  >
                    Log Out
                  </Text>
                )}
                {!currentUser && (
                  <Text
                    cursor={"pointer"}
                    onClick={() => {
                      analytics.t("nav: sign in");
                      presentAuthModal();
                    }}
                  >
                    Sign-In
                  </Text>
                )}
                {kydEvent ? (
                  <Text
                    cursor={"pointer"}
                    onClick={() => {
                      analytics.t("nav: my tickets");
                      const searchParams = new URLSearchParams(
                        window.location.search
                      );

                      navigate(
                        `/e/${event_id}/wallet?${searchParams.toString()}`
                      );
                    }}
                  >
                    My{" "}
                    {kydEvent?.display_mode === "normal" ? "Tickets" : "RSVPs"}
                    {wallet && wallet.tickets.length > 0
                      ? ` (${wallet.tickets.length})`
                      : ""}
                  </Text>
                ) : null}

                <HStack
                  py={[4, null, 0]}
                  cursor={"pointer"}
                  onClick={() => {
                    analytics.t("nav: cart");
                    const searchParams = new URLSearchParams(
                      window.location.search
                    );
                    navigate(`/e/${event_id}/cart?${searchParams.toString()}`);
                  }}
                >
                  <Icon color="white" fontSize={"2xl"} as={FaShoppingCart} />

                  {cart &&
                    cart.ticket_quantity > 0 &&
                    !queryParams.has("checkout_session_id") && (
                      <Text
                        px="2"
                        fontSize={"md"}
                        fontWeight="bold"
                        rounded={"md"}
                        bg="red.500"
                        color="white"
                      >
                        {(cart as KYDCartV2).ticket_quantity}
                      </Text>
                    )}
                  {expiresMs > 0 && !queryParams.has("checkout_session_id") && (
                    <HStack
                      fontSize={"lg"}
                      fontWeight={"bold"}
                      color="white"
                      spacing={0}
                    >
                      <Text minW={"60px"}>
                        <KYDCountdown
                          ms={expiresMs}
                          didComplete={onCartExpire}
                        />
                      </Text>
                    </HStack>
                  )}
                </HStack>
              </HStack>
            </HStack>
          </VStack>
        </Box>
        {kydEvent && (
          <VStack
            pt={{ base: "0px", md: "50px" }}
            pb={[0, null, 10]}
            spacing={{ base: 0, md: 4 }}
            w="100%"
            maxW={["100%", null, "4xl"]}
            flexGrow={4}
          >
            <Box
              w="100%"
              bg={{ base: "transparent", md: "white" }}
              rounded={{ base: "none", md: "lg" }}
              borderColor="gray.200"
              shadow={"lg"}
              borderWidth={{ base: "0px", md: "1px" }}
              p={[3, null, 5]}
            >
              <Stack
                flexDir={["column", null, "row"]}
                spacing={{ base: 2, md: 0 }}
                alignItems={{ base: "center", md: "flex-start" }}
                w="100%"
              >
                {kydEvent?.image && (
                  <Image
                    rounded={"lg"}
                    maxW={"200px"}
                    maxH={"200px"}
                    cursor={"pointer"}
                    fit={"contain"}
                    onClick={() => {
                      setModalType(ModalType.LIGHTBOX, kydEvent.image);
                    }}
                    src={kydEvent?.image}
                    mr={[0, null, 4]}
                    display={{ base: "inherit", md: "table-cell" }}
                  />
                )}
                <Stack
                  color={{ base: "white", md: "black" }}
                  textAlign={"left"}
                >
                  <Stack spacing={{ base: 2, md: 4 }}>
                    <Text
                      onClick={() => {
                        const searchParams = new URLSearchParams(
                          window.location.search
                        );
                        navigate(`/e/${event_id}?${searchParams.toString()}`);
                      }}
                      cursor="pointer"
                      fontSize={"2xl"}
                      fontWeight={"bold"}
                    >
                      {kydEvent.name}
                    </Text>
                    {kydEvent?.display_options?.attendees_hosted ? (
                      <VStack w="full" alignItems={"flex-start"}>
                        <Box
                          bg="red.200"
                          rounded={"md"}
                          px={2}
                          py={1}
                          fontSize={"xs"}
                          color={['gray.800', 'white']}
                        >
                          <InlineText fontWeight={"bold"} mr={1}>
                            {kydEvent?.display_options.attendees_hosted}
                          </InlineText>
                          attendees hosted 📈
                        </Box>
                      </VStack>
                    ) : null}
                    <HStack align={"top"}>
                      <Icon mt={1} as={FaCalendar} />
                      <Text
                        display={{ base: "none", md: "block" }}
                        fontWeight={"medium"}
                      >
                        {`${kydEvent.display_start_at}`}
                      </Text>
                      <HStack>
                        <Text
                          display={{ base: "block", md: "none" }}
                          fontWeight={"medium"}
                        >
                          {`${kydEvent.display_start_at}`}
                        </Text>
                      </HStack>
                    </HStack>
                    <HStack align={"center"}>
                      <Icon mt={1} as={FaMapPin} />
                      <Stack spacing={0}>
                        <Text fontWeight={"bold"}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: linkColor,
                              textDecoration: linkDecoration,
                              fontStyle: "bold",
                            }}
                            href={kydEvent.venues[0]?.link}
                          >
                            {kydEvent.venues[0]?.name}
                          </a>
                        </Text>
                        <Stack spacing={0}>
                          <Text fontSize={"sm"} fontWeight={"medium"}>
                            {kydEvent.venues[0].address}
                          </Text>
                        </Stack>
                      </Stack>
                    </HStack>
                    {/*kydEvent.display_doors_at ? (
                      <HStack>
                        <Icon
                          color={{ base: "white", md: "gray.500" }}
                          as={FaDoorOpen}
                        />
                        <Stack spacing={0}>
                          <Text
                            color={{ base: "white", md: "gray.500" }}
                            fontSize={"sm"}
                            fontWeight={"medium"}
                          >
                            {`Doors open at ${kydEvent.display_doors_at}`}
                          </Text>
                        </Stack>
                      </HStack>
                    ) : null*/}
                  </Stack>

                  <Box display={{ base: "none", md: "block" }}>
                    <Stack
                      spacing={1}
                      pos="relative"
                      onClick={() => setExpanded(!isExpanded)}
                      {...getCollapseProps()}
                    >
                      <Box
                        pointerEvents={"none"}
                        h="50px"
                        pos="absolute"
                        right={0}
                        left={0}
                        bottom={0}
                        background={
                          !isExpanded
                            ? `linear-gradient(to bottom, transparent, ${
                                isMobile ? "black" : "white"
                              })`
                            : null
                        }
                      ></Box>
                      {descriptionWithLineBreaks ? (
                        <Markdown
                          className="mdx-editor"
                          components={{
                            a: (props) => (
                              <Link
                                href={props.href}
                                target={"_blank"}
                                textDecoration={"underline"}
                                color={isMobile ? "blue.200" : "blue.800"}
                              >
                                {props.children}
                              </Link>
                            ),
                            h1: (props) => <Heading {...props} fontSize={24} />,
                            h2: (props) => <Heading {...props} fontSize={22} />,
                            h3: (props) => <Heading {...props} fontSize={20} />,
                            h4: (props) => <Heading {...props} fontSize={18} />,
                            h5: (props) => <Heading {...props} fontSize={16} />,
                            p: Text,
                          }}
                        >
                          {descriptionWithLineBreaks}
                        </Markdown>
                      ) : null}
                    </Stack>
                    {kydEvent.description &&
                    kydEvent.description.length > 50 ? (
                      <Text
                        onClick={() => setExpanded(!isExpanded)}
                        cursor="pointer"
                        fontSize={"xs"}
                        fontWeight={"bold"}
                      >
                        see {isExpanded ? "less" : "more"}
                      </Text>
                    ) : null}
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Box
              w="100%"
              rounded={{ base: "none", md: "lg" }}
              borderColor="gray.200"
              shadow={"lg"}
            >
              <Stack spacing={0}>
                <Routes>
                  <Route
                    path="cart"
                    element={
                      <Cart
                        stripeObject={stripeObject}
                        stripeRef={stripeRef}
                        elementsRef={elementsRef}
                        radarRef={radarRef}
                        kydEvent={kydEvent}
                        currentUser={currentUser}
                        cart={cart!}
                        onCheckout={onCheckout}
                        onSignIn={presentAuthModal}
                        onClearCart={onClearCart}
                        checkoutLoading={checkoutLoading}
                        cleanPromocodeErrors={() => cleanError("promocode")}
                        promocodeErrors={promocodeErrors}
                        onApplyPromoCode={onApplyPromoCode}
                        onRemovePromocode={onRemovePromocode}
                        modalType={modalType}
                      />
                    }
                  />
                  <Route
                    path="wallet"
                    element={
                      <KYDEventWallet
                        kydEvent={kydEvent}
                        onListTickets={onListTickets}
                        authState={authState}
                        loading={_loading}
                        setLoading={setLoading}
                        wallet={wallet}
                        setModalType={setModalType}
                        onSignIn={presentAuthModal}
                        fetchWallet={fetchWallet}
                        onLeaveWaitlist={onLeaveWaitlist}
                      />
                    }
                  />
                  <Route
                    path="success"
                    element={<KYDSuccess kydEvent={kydEvent} />}
                  />
                  <Route
                    path="waitlist"
                    element={
                      <WaitlistCheckout
                        stripeObject={stripeObject}
                        kydEvent={kydEvent}
                        onCreateWaitlistBuyOrder={onCreateWaitlistBuyOrder}
                        isLoading={checkoutLoading}
                        cart={waitlistCart}
                        stripeRef={stripeRef}
                        elementsRef={elementsRef}
                        radarRef={radarRef}
                      />
                    }
                  />
                </Routes>
              </Stack>
            </Box>
            <Routes>
              <Route
                path="/"
                element={
                  <KYDEventDetail
                    onGoToTicketCode={(code) => {
                      setSearchParams({ code });
                      setAccessCode(code);
                    }}
                    code={code}
                    wallet={wallet}
                    kydEvent={kydEvent}
                    cart={cart}
                    loading={_loading}
                    onRemindMeWhenOnSale={onRemindMeWhenOnSale}
                    draftCart={draftCart}
                    onJoinWaitlist={
                      isUserLoggedIn ? onJoinWaitlist : onSignInToJoinWaitlist
                    }
                    onAddToCart={onAddToCart}
                    onRemindMe={onRemindMe}
                    onUpdateTicketTypeQuantity={onUpdateTicketTypeQuantity}
                    modalType={modalType}
                  />
                }
              />
            </Routes>
            <EventFooter
              showIntercom={showIntercom}
              faq_link={kydEvent?.faq_link}
              logoFooterDark={logoFooterDark}
            />
          </VStack>
        )}

        <VStack
          display={{ base: "flex", md: "none" }}
          py={4}
          bg="transparent"
          w="100%"
          color="white"
        >
          <VStack pb={2} spacing={1}>
            <a
              href="https://kydlabs.com?ref=event"
              target="_blank"
              rel="noreferrer"
            >
              <Image maxW={"75px"} src={logoFooter} />
            </a>
            <HStack>
              <Text fontSize={"xs"} textDecor={"underline"}>
                <a
                  href="https://www.iubenda.com/terms-and-conditions/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Terms Of Service
                </a>
              </Text>
              <Text fontSize={"xs"} textDecor={"underline"}>
                <a
                  href="https://www.iubenda.com/privacy-policy/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Text>
              {kydEvent && kydEvent.faq_link && (
                <Text fontSize={"xs"} textDecor={"underline"}>
                  <a
                    href={kydEvent.faq_link}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    FAQ
                  </a>
                </Text>
              )}
              <Text
                onClick={() => showIntercom()}
                fontSize={"xs"}
                textDecor={"underline"}
                cursor="pointer"
              >
                Help
              </Text>
            </HStack>

            <HStack>
              <Text color="gray.200" fontSize={"xs"}>
                © {new Date().getFullYear()} KYD Labs Inc.
              </Text>
              <Text color="gray.400" fontSize={"xs"}>
                v{process.env.REACT_APP_VERSION}
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </Div100vh>
  );
}

export default withWaitlistModal(
  withWaitlistPaymentMethodConfirmationModal(withFullScreenModal(KYDEvent))
);
